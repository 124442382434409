import { default as editDWS3HeXeVtMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/edit.vue?macro=true";
import { default as indexy6Z3rj1lc0Meta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/index.vue?macro=true";
import { default as indexUd3p3wH7vxMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/accounts/index.vue?macro=true";
import { default as indexIRDHIVvgCEMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/ancillaryWorks/index.vue?macro=true";
import { default as indexcNERBRvbBfMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFileLabels/index.vue?macro=true";
import { default as indexhoa3D1v3EJMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/[checkFileId]/index.vue?macro=true";
import { default as indexIaQxLhnqWIMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/index.vue?macro=true";
import { default as index9I4KK7kWtqMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheetLabels/index.vue?macro=true";
import { default as index4oITiaxoCiMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/[checkSheetId]/index.vue?macro=true";
import { default as indexRNhlWDRdP4Meta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/index.vue?macro=true";
import { default as editsTOF1WBF0xMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/edit.vue?macro=true";
import { default as indexGO39xpuzL8Meta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/[checkFileId]/[yyyy]/[mm]/index.vue?macro=true";
import { default as indexIoapjWel8NMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/index.vue?macro=true";
import { default as indexm6en4oG2VtMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/[workFileId]/index.vue?macro=true";
import { default as indexYK1Iz8fkmIMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/index.vue?macro=true";
import { default as indexJRHYKDwPhcMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/index.vue?macro=true";
import { default as indexD72uRcMBIDMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/[monthlyWorkFileId]/index.vue?macro=true";
import { default as indexZooVJo3iS6Meta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/index.vue?macro=true";
import { default as indexKCqqlPVeHFMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/tickets/index.vue?macro=true";
import { default as indexLfiJehyE7WMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/noteCategories/index.vue?macro=true";
import { default as indexAjz3GXCRLpMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/tickets/[ticketId]/index.vue?macro=true";
import { default as indexb0S1djHTH9Meta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/tickets/index.vue?macro=true";
import { default as index6J5ME2rxaEMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/timeboxes/index.vue?macro=true";
import { default as indexAsiQVbsSXxMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/users/index.vue?macro=true";
import { default as indexuEPbPK3azJMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/index.vue?macro=true";
import { default as edit0EXFk1IsahMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/edit.vue?macro=true";
import { default as passwordEditeuuamNx8stMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/passwordEdit.vue?macro=true";
import { default as setupfppjCk5hETMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/setup.vue?macro=true";
import { default as index8d8Z1R7EYFMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/users/index.vue?macro=true";
import { default as indexyjIpAaTmfAMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/images/[organizationId]/[objectId]/index.vue?macro=true";
import { default as indexxT8eIfQFp7Meta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/index.vue?macro=true";
import { default as logink5JuwWjfneMeta } from "/home/runner/work/checkup/checkup/apps/admin/src/pages/login.vue?macro=true";
export default [
  {
    name: "organizationId-edit",
    path: "/:organizationId()/edit",
    meta: editDWS3HeXeVtMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/edit.vue")
  },
  {
    name: "organizationId",
    path: "/:organizationId()",
    meta: indexy6Z3rj1lc0Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-accounts",
    path: "/:organizationId()/locations/:locationId()/accounts",
    meta: indexUd3p3wH7vxMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/accounts/index.vue")
  },
  {
    name: "organizationId-locations-locationId-ancillaryWorks",
    path: "/:organizationId()/locations/:locationId()/ancillaryWorks",
    meta: indexIRDHIVvgCEMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/ancillaryWorks/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkFileLabels",
    path: "/:organizationId()/locations/:locationId()/checkFileLabels",
    meta: indexcNERBRvbBfMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFileLabels/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkFiles-checkFileId",
    path: "/:organizationId()/locations/:locationId()/checkFiles/:checkFileId()",
    meta: indexhoa3D1v3EJMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/[checkFileId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkFiles",
    path: "/:organizationId()/locations/:locationId()/checkFiles",
    meta: indexIaQxLhnqWIMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkFiles/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkSheetLabels",
    path: "/:organizationId()/locations/:locationId()/checkSheetLabels",
    meta: index9I4KK7kWtqMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheetLabels/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkSheets-checkSheetId",
    path: "/:organizationId()/locations/:locationId()/checkSheets/:checkSheetId()",
    meta: index4oITiaxoCiMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/[checkSheetId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-checkSheets",
    path: "/:organizationId()/locations/:locationId()/checkSheets",
    meta: indexRNhlWDRdP4Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/checkSheets/index.vue")
  },
  {
    name: "organizationId-locations-locationId-edit",
    path: "/:organizationId()/locations/:locationId()/edit",
    meta: editsTOF1WBF0xMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/edit.vue")
  },
  {
    name: "organizationId-locations-locationId-monthlyWorkFiles-checkFileId-yyyy-mm",
    path: "/:organizationId()/locations/:locationId()/monthlyWorkFiles/:checkFileId()/:yyyy()/:mm()",
    meta: indexGO39xpuzL8Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/[checkFileId]/[yyyy]/[mm]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-monthlyWorkFiles",
    path: "/:organizationId()/locations/:locationId()/monthlyWorkFiles",
    meta: indexIoapjWel8NMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/monthlyWorkFiles/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-dailyVerifications-workFileId",
    path: "/:organizationId()/locations/:locationId()/myPage/dailyVerifications/:workFileId()",
    meta: indexm6en4oG2VtMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/[workFileId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-dailyVerifications",
    path: "/:organizationId()/locations/:locationId()/myPage/dailyVerifications",
    meta: indexYK1Iz8fkmIMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/dailyVerifications/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage",
    path: "/:organizationId()/locations/:locationId()/myPage",
    meta: indexJRHYKDwPhcMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-monthlyVerifications-monthlyWorkFileId",
    path: "/:organizationId()/locations/:locationId()/myPage/monthlyVerifications/:monthlyWorkFileId()",
    meta: indexD72uRcMBIDMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/[monthlyWorkFileId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-monthlyVerifications",
    path: "/:organizationId()/locations/:locationId()/myPage/monthlyVerifications",
    meta: indexZooVJo3iS6Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/monthlyVerifications/index.vue")
  },
  {
    name: "organizationId-locations-locationId-myPage-tickets",
    path: "/:organizationId()/locations/:locationId()/myPage/tickets",
    meta: indexKCqqlPVeHFMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/myPage/tickets/index.vue")
  },
  {
    name: "organizationId-locations-locationId-noteCategories",
    path: "/:organizationId()/locations/:locationId()/noteCategories",
    meta: indexLfiJehyE7WMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/noteCategories/index.vue")
  },
  {
    name: "organizationId-locations-locationId-tickets-ticketId",
    path: "/:organizationId()/locations/:locationId()/tickets/:ticketId()",
    meta: indexAjz3GXCRLpMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/tickets/[ticketId]/index.vue")
  },
  {
    name: "organizationId-locations-locationId-tickets",
    path: "/:organizationId()/locations/:locationId()/tickets",
    meta: indexb0S1djHTH9Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/tickets/index.vue")
  },
  {
    name: "organizationId-locations-locationId-timeboxes",
    path: "/:organizationId()/locations/:locationId()/timeboxes",
    meta: index6J5ME2rxaEMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/timeboxes/index.vue")
  },
  {
    name: "organizationId-locations-locationId-users",
    path: "/:organizationId()/locations/:locationId()/users",
    meta: indexAsiQVbsSXxMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/[locationId]/users/index.vue")
  },
  {
    name: "organizationId-locations",
    path: "/:organizationId()/locations",
    meta: indexuEPbPK3azJMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/locations/index.vue")
  },
  {
    name: "organizationId-myself-edit",
    path: "/:organizationId()/myself/edit",
    meta: edit0EXFk1IsahMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/edit.vue")
  },
  {
    name: "organizationId-myself-passwordEdit",
    path: "/:organizationId()/myself/passwordEdit",
    meta: passwordEditeuuamNx8stMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/passwordEdit.vue")
  },
  {
    name: "organizationId-myself-setup",
    path: "/:organizationId()/myself/setup",
    meta: setupfppjCk5hETMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/myself/setup.vue")
  },
  {
    name: "organizationId-users",
    path: "/:organizationId()/users",
    meta: index8d8Z1R7EYFMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/[organizationId]/users/index.vue")
  },
  {
    name: "images-organizationId-objectId",
    path: "/images/:organizationId()/:objectId()",
    meta: indexyjIpAaTmfAMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/images/[organizationId]/[objectId]/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logink5JuwWjfneMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/admin/src/pages/login.vue")
  }
]