<script setup lang="ts">
import type { RouteLocationNamedRaw } from 'vue-router'

const route = useRoute()
const currentRouteName = computed(() => route.name?.toString())
const isActiveMenu = (name: string) => currentRouteName.value === name

defineProps<{
  title: string
  menus: { to: RouteLocationNamedRaw; text: string }[]
}>()
</script>

<template>
  <p class="sidebar-menu-title">{{ title }}</p>
  <ul class="sidebar-menu-list">
    <li
      v-for="(menu, i) in menus"
      :key="i"
      class="sidebar-menu-item sub"
      :class="{ active: isActiveMenu(menu.to.name?.toString() ?? '') }"
    >
      <SidebarLink :to="menu.to" :text="menu.text" />
    </li>
  </ul>
</template>

<style scoped lang="scss">
@use '~/assets/scss/color.scss' as colorToken;

.sidebar-menu-title {
  font-size: 1rem;
  padding: 16px 0 8px 24px;
}
.sidebar-menu-list {
  padding: 8px 0px;
  .sidebar-menu-item {
    border-left: 4px solid transparent;
    a {
      display: block;
      color: colorToken.$neutral-weak-text;
      padding: 8px;
    }
    &.sub {
      a {
        padding-left: 40px;
      }
    }
    &:not(.active):hover {
      background-color: colorToken.$neutral-x-weak-surface;
    }
  }
  .active {
    background-color: colorToken.$brand-weak-surface;
    border-left: 4px solid colorToken.$brand-strong-border;
    a {
      font-weight: bold;
      color: colorToken.$brand-strong-text;
    }
  }
}
</style>
