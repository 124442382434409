<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { currentUser } = useCurrentUser()
const { t } = useI18n()

const route = useRoute()
const currentRouteName = computed(() => route.name?.toString())
const isActiveMenu = (name: string) => currentRouteName.value === name

const masterMenu = computed(() => {
  const menus = [
    {
      to: { name: 'organizationId-locations-locationId-checkFiles' },
      text: t('チェックファイル'),
    },
  ]
  if (currentUser.value?.canEditLocation) {
    menus.push({
      to: { name: 'organizationId-locations-locationId-checkSheets' },
      text: t('チェックシート'),
    })
    menus.push({
      to: { name: 'organizationId-locations-locationId-timeboxes' },
      text: t('詳細設定'),
    })
  }
  return menus
})
</script>

<template>
  <div>
    <CurrentLocationDropdown class="locations-dropdown" />
    <aside class="menu">
      <ul>
        <li class="sidebar-menu-item" :class="{ active: isActiveMenu('organizationId-locations-locationId-myPage') }">
          <SidebarLink :to="{ name: 'organizationId-locations-locationId-myPage' }" :text="$t('マイページ')" />
        </li>

        <li>
          <BaseSidebar :title="$t('マスタ')" :menus="masterMenu" />
        </li>

        <li
          class="sidebar-menu-item"
          :class="{ active: isActiveMenu('organizationId-locations-locationId-monthlyWorkFiles') }"
        >
          <SidebarLink :to="{ name: 'organizationId-locations-locationId-monthlyWorkFiles' }" :text="$t('作業記録')" />
        </li>

        <li class="sidebar-menu-item" :class="{ active: isActiveMenu('organizationId-locations-locationId-tickets') }">
          <SidebarLink :to="{ name: 'organizationId-locations-locationId-tickets' }" :text="$t('チケット')" />
        </li>

        <li v-if="currentUser?.role !== 'WORK_ADMIN'">
          <BaseSidebar
            :title="$t('拠点設定')"
            :menus="[
              {
                to: { name: 'organizationId-locations-locationId-edit' },
                text: $t('情報'),
              },
              {
                to: { name: 'organizationId-locations-locationId-accounts' },
                text: $t('作業者'),
              },
              {
                to: { name: 'organizationId-locations-locationId-users' },
                text: $t('管理者'),
              },
            ]"
          />
        </li>
      </ul>
    </aside>
  </div>
</template>

<style lang="scss" scoped>
@use '~/assets/scss/color.scss' as colorToken;

.locations-dropdown {
  margin: 16px;
}
.sidebar-menu-item {
  border-left: 4px solid transparent;
  a {
    display: block;
    color: colorToken.$neutral-weak-text;
    padding: 10px 10px 10px 20px;
  }
  &:not(.active):hover {
    background-color: colorToken.$neutral-x-weak-surface;
  }
}
.active {
  background-color: colorToken.$brand-weak-surface;
  border-left: 4px solid colorToken.$brand-strong-border;
  a {
    font-weight: bold;
    color: colorToken.$brand-strong-border;
  }
}
</style>
