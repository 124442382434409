<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const { signOut } = useAuth()
const { currentUser } = useCurrentUser()
const { organization } = useOrganization()

const handleSignOut = async () => {
  await signOut()
  await navigateTo('/login')
}

const open = ref(false)
const menuRoot = ref(null)
const closeDropdown = () => {
  open.value = false
}
onClickOutside(menuRoot, closeDropdown)
</script>

<template>
  <header v-if="organization">
    <nav class="navbar has-shadow">
      <div class="navbar-brand">
        <NuxtLink class="navbar-item" to="/"><CheckupLogo /></NuxtLink>
        <span class="navbar-item">{{ `${organization.display_name} (ID: ${organization.name})` }}</span>
      </div>

      <div class="navbar-menu">
        <div ref="menuRoot" class="navbar-end">
          <div>
            <button class="navbar-menu-button" @click="open = !open">
              <PersonIcon />
              <span v-if="currentUser">{{ currentUser.name }}</span>
              <ExpandMoreIcon />
            </button>
            <div v-if="open" class="navbar-menu-block">
              <NuxtLink
                v-if="currentUser?.role === 'ORGANIZATION_ADMIN'"
                v-t="'組織設定'"
                class="navbar-item"
                :to="{ name: 'organizationId-edit', params: { organizationId: organization.id } }"
              />
              <NuxtLink
                v-t="'個人設定'"
                class="navbar-item"
                :to="{ name: 'organizationId-myself-edit', params: { organizationId: organization.id } }"
              />
              <hr class="navbar-divider" />
              <a v-t="'ログアウト'" class="navbar-item" @click="handleSignOut"></a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
@use '~/assets/scss/color.scss' as colorToken;

.navbar-menu-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 4px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}
.navbar-menu-block {
  position: absolute;
  z-index: 10; // TODO: z-indexは変数管理したい
  width: 180px;
  right: 2px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  a {
    &:hover {
      color: colorToken.$neutral-weak-text;
      background-color: colorToken.$neutral-x-weak-surface;
    }
  }
}
</style>
