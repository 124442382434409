<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { getLocations } from '~/repositories/locationRepository'

const route = useRoute()
const { currentUser } = useCurrentUser()
const { currentLocationId } = useCurrentLocation()
const { t } = useI18n()
const { addToast } = useToast()

const { data: locations } = await useAsyncDataWithCapture(async () => {
  return await getLocations()
})
const switchLocation = async (id: number | null) => {
  if (!id) return
  currentLocationId.value = id
  await refreshNuxtData()
  await navigateTo({
    name: route.name?.toString(),
    params: { ...route.params, locationId: id },
  })
  addToast({
    color: 'success',
    message: t('拠点を切り替えました。'),
  })
}
</script>

<template>
  <BaseDropdown
    v-if="locations && currentUser"
    width="220px"
    :model-value="currentLocationId"
    :items="locations"
    :disabled="currentUser.role !== 'ORGANIZATION_ADMIN'"
    @update:model-value="switchLocation"
  />
</template>
