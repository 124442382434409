import revive_payload_client_A6vchyjck1 from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__vkhz5alh3sg3vzlpiwlhynsh4u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HXTxRF2Fm1 from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__vkhz5alh3sg3vzlpiwlhynsh4u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_M8Pd5Ioxy4 from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__vkhz5alh3sg3vzlpiwlhynsh4u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_bSqAkbo1c7 from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__vkhz5alh3sg3vzlpiwlhynsh4u/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_IYiO7nlnhR from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__vkhz5alh3sg3vzlpiwlhynsh4u/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nQecd3LbIW from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__vkhz5alh3sg3vzlpiwlhynsh4u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GYo661qfru from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__vkhz5alh3sg3vzlpiwlhynsh4u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/checkup/checkup/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_woKNXmzB9V from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__vkhz5alh3sg3vzlpiwlhynsh4u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_Vu6b6nytQn from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/supabase.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/sentry.client.ts";
import dayjs_client_A2bzKURDlV from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/dayjs.client.ts";
import gtag_6Z9lu4VVCL from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/gtag.ts";
import i18n_YCWKwfVXF6 from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/i18n.ts";
import qrcode_client_gU8ufMWnQ5 from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/qrcode.client.ts";
import vuedraggable_ajhfMTWOKD from "/home/runner/work/checkup/checkup/apps/admin/src/plugins/vuedraggable.ts";
export default [
  revive_payload_client_A6vchyjck1,
  unhead_HXTxRF2Fm1,
  router_M8Pd5Ioxy4,
  payload_client_bSqAkbo1c7,
  navigation_repaint_client_IYiO7nlnhR,
  check_outdated_build_client_nQecd3LbIW,
  chunk_reload_client_GYo661qfru,
  components_plugin_KR1HBZs4kY,
  prefetch_client_woKNXmzB9V,
  supabase_client_Vu6b6nytQn,
  sentry_client_KAXFuL2wum,
  dayjs_client_A2bzKURDlV,
  gtag_6Z9lu4VVCL,
  i18n_YCWKwfVXF6,
  qrcode_client_gU8ufMWnQ5,
  vuedraggable_ajhfMTWOKD
]