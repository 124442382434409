import * as Sentry from '@sentry/vue'
import type { AsyncData, AsyncDataOptions, PickFrom, KeysOf } from '#app/composables/asyncData'
import type { NuxtApp, NuxtError } from '#app'

import { uuid } from '~/utils/uuid'

type Handler<ResT> = (ctx?: NuxtApp) => Promise<ResT>

export function useAsyncDataWithCapture<
  ResT,
  NuxtErrorDataT = unknown,
  DataT = ResT,
  PickKeys extends KeysOf<DataT> = KeysOf<DataT>,
  DefaultT = null
>(
  handler: Handler<ResT>,
  options?: AsyncDataOptions<ResT, DataT, PickKeys, DefaultT>
): AsyncData<
  PickFrom<DataT, PickKeys> | DefaultT,
  (NuxtErrorDataT extends Error | NuxtError ? NuxtErrorDataT : NuxtError<NuxtErrorDataT>) | null
> {
  const cacheKey = uuid()
  return useAsyncData(cacheKey, captureWrapper(handler), options)
}

const captureWrapper: <T>(handler: Handler<T>) => Handler<T> = (handler) => {
  return async (ctx) => {
    try {
      return await handler(ctx)
    } catch (e) {
      Sentry.captureException(e)

      throw e
    }
  }
}
